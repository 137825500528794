import { combineReducers, Reducer } from "redux";
import UserReducer, { UserState } from "./reducers/auth";
import ProducerAdminReducer, { ProducerAdminState } from "./reducers/producer";
import ProducerStockReducer, { ProducerStockState } from './reducers/stock';

export interface ApplicationState {
    user: UserState;
    producerAdmin: ProducerAdminState;
    stock: ProducerStockState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    user: UserReducer,
    producerAdmin: ProducerAdminReducer,
    stock: ProducerStockReducer,
});