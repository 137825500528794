import { Reducer } from "react";
import { ProducerStock } from "../../model/ProducerStock";
import { Product } from "../../model/Product";
import { ProductCategory } from "../../model/ProductCategory";
import { ProducerStockAction } from '../actions/stock';

export interface ProducerStockState {
    stock: ProducerStock | undefined;
    categories: ProductCategory[];
    history: Product[];
}

const initialState: ProducerStockState = {
    stock: null,
    categories: null,
    history: [],
}

const ProducerStockReducer: Reducer<ProducerStockState, ProducerStockAction> = (state: ProducerStockState = initialState, action: ProducerStockAction) => {
    switch (action.type) {
        case '@@adminProducer/GET_STOCK':
            return { ...state, stock: action.payload.stock };

        case '@@adminProducer/GET_STOCK_ALL_CATEGORIES':
            return { ...state, categories: action.payload.stockCategories }

        case '@@adminProducer/STOCK_ADD_PRODUCT':
            return {
                ...state,
                stock: {
                    ...state.stock,
                    products: [...state.stock.products, action.payload.product]
                }
            }

        case '@@adminProducer/STOCK_UPDATE_PRODUCT': {
            const updatedProduct = action.payload.product;

            const originalProducts = [...state.stock.products]

            const originalProduct = originalProducts.find(it => it.id === updatedProduct.id);
            const originalProductIndex = originalProducts.indexOf(originalProduct);
            originalProducts.splice(originalProductIndex, 1, updatedProduct);

            return {
                ...state,
                stock: {
                    ...state.stock,
                    products: [...originalProducts]
                }
            }
        }

        case '@@adminProducer/STOCK_DELETE_PRODUCT': {
            const deletedProductId = action.payload.productId;

            const originalProducts = [...state.stock.products]

            const deletedProduct = state.stock.products.find(it => it.id === deletedProductId);
            const deletedProductIndex = originalProducts.indexOf(deletedProduct);

            originalProducts.splice(deletedProductIndex, 1);

            return {
                ...state,
                stock: {
                    ...state.stock,
                    products: [...originalProducts]
                }
            }
        }

        case '@@adminProducer/DISCARD_UNSAVED': {
            const originalProducts = [...state.stock.products];
            return {
                ...state,
                stock: {
                    ...state.stock,
                    products: [...originalProducts.filter(it => it.id > 0)]
                }
            }
        }

        case '@@adminProducer/STOCK_FIND_PRODUCT_HISTORY': {
            return { ...state, history: action.payload.history }
        }

        default:
            return state;
    }
}

export default ProducerStockReducer;