import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ProducerStock } from "../model/ProducerStock";
import { ProductCategory } from "../model/ProductCategory";
import { ApplicationState } from "../store/reducers";
import { PlaiuriDropdownSelect } from "./PlaiuriDropdownSelect";

interface ModalProps {
    title: string;
    label: string;
    onSave: (value: ProductCategory) => void;
    onClose: () => void;
}

export default function CategorySelectModal(props: ModalProps) {

    const stock: ProducerStock = useSelector<ApplicationState, ProducerStock | undefined>(state => state.stock.stock);
    const categories: ProductCategory[] = useSelector<ApplicationState, ProductCategory[]>(state => state.stock.categories);

    const [categoryOptions, setCategoryOptions] = useState<ProductCategory[]>([]);
    const [categoryAdded, setCategoryAdded] = useState<ProductCategory>(null);

    useEffect(() => {
        if (stock === null || categories === null) {
            return;
        }

        const existingCategories = stock.products
                                        .map(it => it.category)
                                        .filter((v, i, a) => a.map(it => it.id).indexOf(v.id) === i);

        const updatedProducerCategories = categories
                                               .filter(itc => !existingCategories.map(it => it.id).includes(itc.id))
                                               .filter((v, i, a) => a.map(it => it.id).indexOf(v.id) === i);
        
        setCategoryOptions(updatedProducerCategories);
        setCategoryAdded(updatedProducerCategories[0]);

    }, [stock, categories]);

    const onCloseCallback = () => {
        props.onClose();
    }

    const onSaveCallback = () => {
        props.onSave(categoryAdded);
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                {props.title}
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => onCloseCallback()}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="w-[500px] mb-6 md:mb-0 my-3 mx-2">
                                <PlaiuriDropdownSelect label={'Categorie produse'}
                                    options={categoryOptions.map(it => it?.name)}
                                    value={categoryAdded ? categoryAdded.name : ''}
                                    onValueChange={(it) => setCategoryAdded(categories.find(ac => ac.name === it))} />

                            </div>
                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => onCloseCallback()}
                            >
                                Close
                            </button>
                            <button
                                className="bg-primary text-white active:bg-primaryDark font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => onSaveCallback()}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}