import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);

reportWebVitals();

// Frameworks:
// - very nice components: https://rsuitejs.com/components/table/
// - reminds me of bootstrap https://mui.com/material-ui/react-autocomplete/

// https://flowbite.com/docs/components/tables/
// + not bad
// + uses tailwindcss 

