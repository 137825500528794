import { Action, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Endpoints } from "../../constants/Endpoints";
import { ProducerStock } from "../../model/ProducerStock";
import { Product } from "../../model/Product";
import { ProductCategory } from "../../model/ProductCategory";
import { ApplicationState } from "../reducers";

export interface StockGetAction extends Action {
    type: "@@adminProducer/GET_STOCK";
    payload: {
        stock: ProducerStock;
    }
}

export const producerGetStock = (producerId: number): ThunkAction<any, {}, {}, StockGetAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockGetAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/stock/${producerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to get producer stock.');
            return;
        }

        const producerStock = await response.json() as ProducerStock;

        dispatch({
            type: "@@adminProducer/GET_STOCK",
            payload: {
                stock: producerStock
            }
        });
    }
}

export interface StockGetAllCategories extends Action {
    type: "@@adminProducer/GET_STOCK_ALL_CATEGORIES";
    payload: {
        stockCategories: ProductCategory[];
    }
}

export const producerStockGetAllCategories = (): ThunkAction<any, {}, {}, StockGetAllCategories> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockGetAllCategories>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/categories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to get producer stock.');
            return;
        }

        const categories = await response.json() as ProductCategory[];

        dispatch({
            type: "@@adminProducer/GET_STOCK_ALL_CATEGORIES",
            payload: {
                stockCategories: categories
            }
        });
    }
}

export interface StockAddProduct extends Action {
    type: "@@adminProducer/STOCK_ADD_PRODUCT";
    payload: {
        product: Product;
    }
}

export const producerStockAddProduct = (stockId: number, productRequest: Product): ThunkAction<any, {}, {}, StockAddProduct> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockAddProduct>, getState): Promise<any> => {

        dispatch({
            type: "@@adminProducer/STOCK_ADD_PRODUCT",
            payload: {
                product: productRequest
            }
        });
    }
}

export const producerStockRemoveProduct = (productId: number): ThunkAction<any, {}, {}, StockDeleteProduct> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockDeleteProduct>, getState): Promise<any> => {

        // product doesn't need to be deleted remotely, but reducer is the same

        dispatch({
            type: "@@adminProducer/STOCK_DELETE_PRODUCT",
            payload: {
                productId: productId
            }
        });
    }
}

export interface StockUpdateProduct extends Action {
    type: "@@adminProducer/STOCK_UPDATE_PRODUCT";
    payload: {
        product: Product;
    }
}

export const producerStockSaveProduct = (stockId: number,
                                        productId: number,
                                        productRequest: Product,
                                        file: any): ThunkAction<any, {}, {}, StockUpdateProduct> => {

    return async (dispatch: ThunkDispatch<{}, {}, StockUpdateProduct>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;
        let response = null;

        if (productId < 0) {
            response = await fetch(`${Endpoints.baseUrl}/stock/${state.producerAdmin.producers[0].id}/${stockId}/product`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(productRequest)
            });

        } else {
            response = await fetch(`${Endpoints.baseUrl}/stock/${state.producerAdmin.producers[0].id}/${stockId}/product/${productId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(productRequest)
            });
        }

        if (!response.ok) {
            alert('Failed to save product details.');
            return;
        }
        let updatedProduct = await response.json() as Product;

        if (file) {
            /*
             * Also update image on the (possibly) newly created product id
             */
            const formData = new FormData();
            formData.append("file", file);

            response = await fetch(`${Endpoints.baseUrl}/stock/${state.producerAdmin.producers[0].id}/${stockId}/product/${updatedProduct.id}/image`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                alert('Failed to upload product image.');
                return;
            }

            updatedProduct = await response.json() as Product;
        }


        dispatch({
            type: "@@adminProducer/STOCK_UPDATE_PRODUCT",
            payload: {
                product: updatedProduct
            }
        });
    }
}

export interface StockDeleteProduct extends Action {
    type: "@@adminProducer/STOCK_DELETE_PRODUCT";
    payload: {
        productId: number;
    }
}

export const producerStockDeleteProduct = (stockId: number, productId: number): ThunkAction<any, {}, {}, StockDeleteProduct> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockDeleteProduct>, getState): Promise<any> => {
        if (productId > 0) {
            const state = getState() as ApplicationState;

            if (state.user.user === null) {
                alert("No authenticated user!");
                return;
            }

            const token = state.user.user?.token;

            const response = await fetch(`${Endpoints.baseUrl}/stock/${state.producerAdmin.producers[0].id}/${stockId}/product/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                alert('Failed to delete product.');
                return;
            }
        }

        dispatch({
            type: "@@adminProducer/STOCK_DELETE_PRODUCT",
            payload: {
                productId: productId
            }
        });
    }
}

export interface StockDiscardUnsavedProduct extends Action {
    type: "@@adminProducer/DISCARD_UNSAVED";
    payload: {}
}

export const producerStockDiscardUnsavedProduct = (): ThunkAction<any, {}, {}, StockDiscardUnsavedProduct> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockDiscardUnsavedProduct>, getState): Promise<any> => {
        dispatch({
            type: "@@adminProducer/DISCARD_UNSAVED",
            payload: {}
        });
    }
}

export interface StockFindProductHistoryAction extends Action {
    type: "@@adminProducer/STOCK_FIND_PRODUCT_HISTORY";
    payload: {
        history: Product[];
    }
}

export const producerStockFindProductHistory = (productId: number): ThunkAction<any, {}, {}, StockFindProductHistoryAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, StockFindProductHistoryAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/stock-history/${state.producerAdmin.producers[0].id}/product/${productId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to get producer stock.');
            return;
        }

        const productHistory = await response.json() as Product[];

        dispatch({
            type: "@@adminProducer/STOCK_FIND_PRODUCT_HISTORY",
            payload: {
                history: productHistory
            }
        });
    }
}

export type ProducerStockAction = StockGetAction | StockGetAllCategories | StockAddProduct | StockUpdateProduct | StockDeleteProduct | StockDiscardUnsavedProduct | StockFindProductHistoryAction;