import React from 'react';

import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import ProducerPage from './pages/ProducerPage';
import { reduxStore } from './store/store';
import AuthGuard from './utils/ProtectedRoute';

const router = createBrowserRouter([
  {
      path: '/',
      element: (
        <AuthGuard>
            <ProducerPage />
        </AuthGuard>),
      errorElement: <ErrorPage />
  },
  {
      path: '/login',
      element: <LoginPage />
  },

]);

function App() {
  return (
      <Provider store={reduxStore}>
          <React.StrictMode>
              <RouterProvider router={router} />
          </React.StrictMode>
      </Provider>
  )
}

export default App;
