import { useEffect, useState } from "react";
import { AiOutlineDownCircle, AiOutlineRightCircle } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { ProducerStock } from "../model/ProducerStock";
import { Product, QuantityUnit, QuantityUnitDetailsCompanionObject } from "../model/Product";
import { ProductCategory } from "../model/ProductCategory";
import { producerStockAddProduct, producerStockDeleteProduct } from "../store/actions/stock";
import { ApplicationState } from "../store/reducers";
import { useAppDispatch } from "../store/store";
import { ProductProps } from "./ProductDetails";

interface CategoryProps {
    category: ProductCategory;
    onProductClick: (p: Product) => void;
    editingProduct: Product;
}

export const ProductDetails = (props: ProductProps) => {
    const dispatch = useAppDispatch();

    const determineColor = () => {
        const selectedColor = '#90caf9';
        const unsavedColor = '#ffcc80';

        if (props.editingProduct?.id === props.product?.id) {
            return selectedColor;

        } else if (props.product.id < 0) {
            return unsavedColor;
        } else {
            return 'white';
        }
    }

    const deleteProduct = () => {
        dispatch(producerStockDeleteProduct(props.stockId, props.product.id));
    }

    return (
        <div className='flex ml-8 cursor-pointer p-2 rounded-md my-2 bg-white shadow-md items-center w-100'
             style={{ backgroundColor: determineColor() }}>

            <div className="flex flex-1 h-9 items-center" onClick={() => props.onClick(props.product)}>
                <div className="grid grid-cols-3 w-full">
                    
                    <div className="flex">
                        <p className='text-m w-8 mx-1'>{props.product.id > 0 ? `#${props.product.id}` : 'n/a'}</p>
                        <p className="text-l flex-1">{props.product.title}</p>
                    </div>

                    <div className="flex">
                        <p className="text-l mx-1 w-8">{props.product.quantity}</p>
                        <p className="text-l mx-1">{QuantityUnitDetailsCompanionObject.makeFromEnum(props.product.quantityUnit)?.plural}</p>
                    </div>

                    <div className="flex justify-end items-center">
                        <p className="text-l mx-1 text-right">{props.product.priceValue}</p>
                        <p className="text-l mx-1">(RON)</p>
                        <p className="text-l mx-1">/</p>
                        <p className="text-l mx-1 w-12">{QuantityUnitDetailsCompanionObject.makeFromEnum(props.product.quantityUnit)?.singular}</p>
                    </div>
                </div>
            </div>

            <BiTrash className='mx-1 text-gray hover:text-red pointer' onClick={deleteProduct} size={20} />
        </div>
    )
}


const ProducerStockCategory = (props: CategoryProps) => {

    const dispatch = useAppDispatch();

    const stock: ProducerStock = useSelector<ApplicationState, ProducerStock | undefined>(state => state.stock.stock);

    const [expanded, setExpanded] = useState(true);
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        if (stock === null) {
            return;
        }

        const updatedProducts = stock.products
            .filter(it => it.category.id === props.category.id)

        setProducts(updatedProducts);
    }, [stock]); // react-hooks/exhaustive-deps

    const createNewProduct = () => {
        const product = new Product(
            Math.trunc(-Math.random() * 100_000), "Nume temporar", "", 0, "RON", 0, QuantityUnit.KILOGRAMS, "", null, []
        );

        product.category = props.category;

        dispatch(producerStockAddProduct(stock.id, product));
    }

    if (!stock) {
        return <></>
    }

    return (
        <div className='ml-2 my-6 transform transition duration-300'>
            <div className='bg-white shadow-md p-2 rounded-md flex items-center cursor-pointer' onClick={() => setExpanded(!expanded)}>
                <p className='text-2xl flex flex-1'>{props.category.name}</p>
                {
                    expanded === false && <AiOutlineRightCircle className='text-primary' size={34} />
                }
                {
                    expanded === true && <AiOutlineDownCircle className='text-primary' size={34} />
                }
            </div>

            {
                expanded && <div>
                    {
                        products.sort((it1, it2) => it1.id - it2.id)
                            .map((it, index) => <ProductDetails key={it.id}
                                stockId={stock.id}
                                product={it}
                                onClick={props.onProductClick}
                                editingProduct={props.editingProduct} />)
                    }

                    <FiPlusCircle size={34} className="ml-8 my-2 cursor-pointer text-primary" onClick={() => { createNewProduct() }} />
                </div>
            }
        </div>
    );
}

export default ProducerStockCategory;