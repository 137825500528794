import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';
import { UserDetails } from '../model/UserDetails';
import { logoutAction } from '../store/actions/auth';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function Header() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const user: UserDetails = useSelector<ApplicationState, UserDetails>(state => state.user.user);

    const navigateHome = () => navigate("/");
    const logout = () => dispatch(logoutAction());


    return (
        <header className='py-4 pb-3 bg-blue shadow-lg top-0 sticky z-50'>

            <div className='flex max-w-[1800px] mx-auto items-center'>

                {/* Middle */}
                <div className='flex flex-1 px-1 md:px-8 items-center cursor-pointer' onClick={() => navigateHome()}>
                    <img src={logo} alt="Logo" width='40'></img>
                    <div className='hidden sm:flex items-center pl-2'>
                        <p className='text-2xl font-sans text-white'>Plaiuri</p>
                        <p className='text-2xl text-white'>.ro</p>
                    </div>
                </div>

                {
                    user && <div className='flex align-middle px-8 text-sm font-sans text-white space-x-4'>
                        <div className='mt-1'>Hello, {user.email}</div>
                        <div className='cursor-pointer font-medium bg-white text-blue px-4 py-1 rounded-full' onClick={logout}>Logout</div>
                    </div>
                }
            </div>
        </header>
    )
}

export default Header