import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { ProductCategory } from "../model/ProductCategory";
import { producerStockDiscardUnsavedProduct } from "../store/actions/stock";
import { useAppDispatch } from "../store/store";
import CategorySelectModal from "./CategorySelectModal";

interface Props {
    onCategoryAdded: (category: ProductCategory) => void;
}

export default function ProducerStockDropdown(props: Props) {
    const dispatch = useAppDispatch();

    const [showDropdown, setShowDropdown] = useState(false);
    const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);

    const discardAll = () => {
        dispatch(producerStockDiscardUnsavedProduct())
    }

    return (
        <div className="inline-flex">
            <div className="relative">
                <FiMenu className='cursor-pointer text-primary mx-2' size={28} onClick={() => setShowDropdown(!showDropdown)} />

                {
                    showDropdown && <div className="absolute right-0 z-10 w-56 mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
                        <div className="p-2">
                            <span
                                className="cursor-pointer block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                                onClick={() => {
                                    setShowCreateCategoryModal(true);
                                    setShowDropdown(false);
                                }}
                            >
                                Creaza categorie
                            </span>

                            {/* <span
                                className="cursor-pointer block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                                onClick={() => {
                                    saveAll();
                                    setShowDropdown(false);
                                }}
                            >
                                Salveaza
                            </span> */}

                            <span
                                className="cursor-pointer block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                                onClick={() => {
                                    discardAll();
                                    setShowDropdown(false);
                                }}
                            >
                                Discard
                            </span>

                        </div>
                    </div>
                }
            </div>

            {
                showCreateCategoryModal && (
                    <CategorySelectModal title={'Creaza categorie'}
                        label={"Nume categorie produse"}
                        onSave={(category) => {
                            props.onCategoryAdded(category);
                            setShowCreateCategoryModal(false);
                        }}
                        onClose={() => {
                            setShowCreateCategoryModal(false)
                        }}
                    />
                )
            }
        </div>
    );
}