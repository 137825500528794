import { Sidebar } from 'flowbite-react';
import { useState } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { BiHistory } from 'react-icons/bi';
import { BsBoxes } from 'react-icons/bs';
import Header from '../components/Header';
import ProducerDetailsHistoryPage from './ProducerDetailsHistoryPage';
import ProducerDetailsPage from './ProducerDetailsPage';
import ProducerStockPage from './ProducerStockPage';
import ProductHistoryPage from './ProductHistoryPage';

function ProducerPage() {

    const [selectedPage, setSelectedPage] = useState<number>(0);

    let selectedPageMap = new Map<number, JSX.Element>([
        [0, <ProducerDetailsPage />],
        [1, <ProducerStockPage />],
        [2, <ProducerDetailsHistoryPage></ProducerDetailsHistoryPage>],
        [3, <ProductHistoryPage></ProductHistoryPage>],
    ]);

    return (
        <div className='body-font font-source-sans-pro'>
            <Header />

            <div className='max-w-[1800px] mx-auto '>

                <div className='flex flex-row items-start py-4'>

                    <Sidebar className='py-4 mx-2 w-56 sticky top-20 z-50'>
                        <Sidebar.Items>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item active={selectedPage === 0} className="cursor-pointer" icon={AiOutlineHome} onClick={() => { setSelectedPage(0) }} >
                                    Producator
                                </Sidebar.Item>

                                <Sidebar.Item active={selectedPage === 1} className="cursor-pointer" icon={BsBoxes} onClick={() => { setSelectedPage(1) }}>
                                    Stocuri
                                </Sidebar.Item>

                                <Sidebar.Collapse icon={BiHistory} label="Istoric">
                                    <Sidebar.Item active={selectedPage === 2} className="cursor-pointer" onClick={() => { setSelectedPage(2) }}>
                                        Producator
                                    </Sidebar.Item>
                                    <Sidebar.Item active={selectedPage === 3} className="cursor-pointer" onClick={() => { setSelectedPage(3) }}>
                                        Produse
                                    </Sidebar.Item>
                                </Sidebar.Collapse>

                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </Sidebar>

                    <div className='flex-1 pr-2'>
                        {selectedPageMap.get(selectedPage)}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProducerPage