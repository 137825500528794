import { ProducerStock } from "./ProducerStock";

export class Producer {
    id: number;
    reviewStatus: string;
    name: string;
    marketingName: string;
    producerType: ProducerType;
    description: string;
    region: string;
    imgUrl?: string;
    stock?: ProducerStock;
    version?: number;
    versionAuthor?: string;
    versionDate?: Date;
    active?: string;

    constructor(id: number, name: string, marketingName: string, producerType: ProducerType, description: string, region: string, imgUrl: string, stock?: ProducerStock) {
        this.id = id;
        this.name = name;
        this.producerType = producerType;
        this.description = description;
        this.region = region;
        this.imgUrl = imgUrl;
        this.stock = stock;
    }
}

export enum ProducerType {
    SRL = "SRL",
    PFA = "PFA",
    PRODUCATOR_AUTORIZAT = "PRODUCATOR_AUTORIZAT"
}