import { Table } from 'flowbite-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Producer } from '../model/Producer';
import { Product } from '../model/Product';
import { producerStockFindProductHistory } from '../store/actions/stock';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProductHistoryPage() {
    const dispatch = useAppDispatch();

    const producer: Producer = useSelector<ApplicationState, Producer | undefined>(state => state.producerAdmin.producers[0]);
    const productHistory: Product[] = useSelector<ApplicationState, Product[]>(state => state.stock.history);

    useEffect(() => {
        if (!producer) {
            return;
        }

        dispatch(producerStockFindProductHistory(producer.id));
    }, [dispatch]);

    return (
        <div className='flex flex-col w-full mt-4 bg-white rounded-md p-4 px-4 mx-2'>
            <p className='m-4 text-3xl'>Istoric produse</p>
            <div className="m-4 mt-8">
                <Table striped={true}>

                    <Table.Head>
                        <Table.HeadCell>
                            ID Produs
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Versiune
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Autor
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Data
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Titlu
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Image URL
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Pret (RON)
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Cantitate
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Unitati cantitate
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Descriere
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Categorie
                        </Table.HeadCell>
                    </Table.Head>

                    <Table.Body className="divide-y">
                        {
                            productHistory.sort((it1, it2) => new Date(it2.versionDate).getTime() - new Date(it1.versionDate).getTime())
                                .map(it => (
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell>
                                            P{it.id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            V{it.version}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.versionAuthor}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {new Date(it.versionDate).toLocaleString("en-US")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.title}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.imgUrl}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.priceValue}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.quantity}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.quantityUnit}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.description}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.category?.name}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default ProductHistoryPage