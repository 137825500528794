import React from 'react'

interface Props {
    imageUrl: string;
    width: number;
    height: number;
}

function PictureImgPicker(props: Props) {
    return (
        <div style={{ backgroundImage: `url(${props.imageUrl})`, width: props.width, height: props.height}} className={`bg-cover bg-center relative mb-2`} >
        </div>
    )
}

export default PictureImgPicker