import { Reducer } from "react";
import { Producer } from "../../model/Producer";
import { ProducerAdminAction } from '../actions/producer';

export interface ProducerAdminState {
    producers: Producer[] | undefined;
    history: Producer[],
}

const initialState: ProducerAdminState = {
    producers: [],
    history: [],
}

const ProducerAdminReducer: Reducer<ProducerAdminState, ProducerAdminAction> = (state: ProducerAdminState = initialState, action: ProducerAdminAction) => {
    switch (action.type) {
        case '@@adminProducer/GET_PRODUCERS': {
            return { ...state, producers: action.payload.producers };
        }

        case '@@adminProducer/SAVE_PRODUCER': {
            const updatedProducer = action.payload.producer;
            const updatedProducers = [...state.producers.filter(it => it.id !== updatedProducer.id), updatedProducer];

            return { ...state, producers: updatedProducers }
        }

        case '@@adminProducer/GET_PRODUCER_HISTORY': {
            return {...state, history: action.payload.history}
        }

        default:
            return state;
    }
}

export default ProducerAdminReducer;