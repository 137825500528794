import { ProductCategory } from './ProductCategory';
import { ProductTag } from './ProductTag';

export class Product {
    id: number;
    title: string;
    imgUrl: string;
    priceValue: number;
    priceCurrency: string;
    quantity: number;
    quantityUnit: QuantityUnit;
    description: string;
    category: ProductCategory;
    tags: ProductTag[];
    
    version?: number;
    versionAuthor?: string;
    versionDate?: Date;

    // does not exist on BE
    imageFile: any;
    producerMarketingName? : string;


    constructor(id: number, title: string, imgUrl: string, priceValue: number, priceCurrency: string, quantity: number, 
                quantityUnit: QuantityUnit, description: string, category: ProductCategory, tags?: ProductTag[]) {

        this.id = id;
        this.title = title;
        this.imgUrl = imgUrl;
        this.priceValue = priceValue;
        this.priceCurrency = priceCurrency;
        this.quantity = quantity;
        this.quantityUnit = quantityUnit;
        this.description = description;
        this.category = category;
        this.tags = tags;
    }
}

export enum QuantityUnit {
    KILOGRAMS = "KILOGRAMS",
    GRAM = "GRAM",
    LITER = "LITER",
    CRATE = "CRATE",
    BOTTLE = "BOTTLE",
    PIECE = "PIECE"
}

export class QuantityUnitDetails {
    constructor(public readonly quantityUnit: QuantityUnit, public readonly singular: string, public readonly plural: string) { }
}

export class QuantityUnitDetailsCompanionObject {
    static mappings: QuantityUnitDetails[] = [
        new QuantityUnitDetails(QuantityUnit.KILOGRAMS, "Kg", "Kg"),
        new QuantityUnitDetails(QuantityUnit.GRAM, "Gram", "Grame"),
        new QuantityUnitDetails(QuantityUnit.LITER, "Litru", "Litrii"),
        new QuantityUnitDetails(QuantityUnit.CRATE, "Ladita", "Ladite"),
        new QuantityUnitDetails(QuantityUnit.BOTTLE, "Sticla", "Sticle"),
        new QuantityUnitDetails(QuantityUnit.PIECE, "Bucata", "Bucati"),
    ]

    public static makeFromString = (val: string): QuantityUnitDetails => {
        return this.mappings.find(it => it.plural === val || it.singular === val);
    }

    public static makeFromEnum = (val: QuantityUnit) => {
        return this.mappings.find(it => it.quantityUnit === val);
    } 

    public static getAllValues = (): QuantityUnitDetails[] => {
        return this.mappings;
    }
}

