import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import NilPictureImgPicker from '../components/NilPictureImgPicker';
import PictureImgPicker from '../components/PictureImgPicker';
import { PlaiuriDropdownSelect } from '../components/PlaiuriDropdownSelect';
import PlaiuriTextarea from '../components/PlaiuriTextarea';
import { PlaiuriTextInput } from '../components/PlaiuriTextInput';
import { COLORS } from '../constants/Colors';
import { Producer, ProducerType } from '../model/Producer';
import { producerAdminDiscardProducerDetailsDraftAction, producerAdminGetProducers, producerAdminSaveProducer } from '../store/actions/producer';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProducerDetailsPage() {
    const dispatch = useAppDispatch();

    const producer = useSelector<ApplicationState, Producer | undefined>(state => state.producerAdmin.producers[0]);

    const [active, setActive] = useState<string>('');
    const [reviewStatus, setReviewStatus] = useState<string>('');
    const [producerName, setProducerName] = useState<string>('');
    const [marketingName, setMarketingName] = useState<string>('');
    const [producerType, setProducerType] = useState<ProducerType>(Object.values(ProducerType)[0]);
    const [producerRegion, setProducerRegion] = useState<string>('');
    const [producerDescription, setProducerDescription] = useState<string>('');

    const [imageUrl, setImageUrl] = useState('');
    const [imageFile, setImageFile] = useState<File>(null);

    const productImageRef = useRef(null);

    useEffect(() => {
        dispatch(producerAdminGetProducers());
    }, [dispatch]);

    useEffect(() => {
        if (!producer) {
            return;
        }

        setActive(producer.active)
        setReviewStatus(producer.reviewStatus ? producer.reviewStatus : reviewStatus)
        setProducerName(producer.name ? producer.name : producerName);
        setMarketingName(producer.marketingName ? producer.marketingName : marketingName);
        setProducerType(producer.producerType ? producer.producerType : producerType);
        setProducerRegion(producer.region ? producer.region : producerRegion);
        setProducerDescription(producer.description ? producer.description : producerDescription);
        setImageUrl(producer.imgUrl);
        setImageFile(null);

    }, [producer]);

    const stateChanged = false
        || producerName !== producer?.name
        || producerType !== producer?.producerType
        || producerRegion !== producer?.region
        || producerDescription !== producer?.description
        || marketingName !== producer?.marketingName
        || imageUrl !== producer?.imgUrl
        || imageFile !== null;

    const saveProducerDetails = () => {
        const producerUpdates = {
            id: -1,
            name: producerName,
            marketingName: marketingName,
            producerType: producerType,
            description: producerDescription,
            region: producerRegion,
        } as Producer;

        dispatch(producerAdminSaveProducer(producer.id, producerUpdates, imageFile));
    }

    const discardProducerDetailsDraft = () => {
        dispatch(producerAdminDiscardProducerDetailsDraftAction(producer.id))
    }

    const shouldShowNilImagePicker = () => {
        if (!imageUrl || imageUrl.length === 0) {
            return true;
        }

        return false;
    }

    const fileUploadCallback = () => {
        productImageRef.current.click();
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!imageFile) {
            return;
        }

        const objectUrl = URL.createObjectURL(imageFile)
        setImageUrl(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const onSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImageFile(undefined);
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setImageFile(e.target.files[0])
    }

    return (
        <div className='flex flex-col w-full mt-4 bg-white rounded-md p-4 px-4 mx-2'>
            <p className='text-3xl'>Detalii producator</p>

            <div className='flex flex-row w-full'>
                <div className='flex-1'>
                    <PlaiuriTextInput readonly label={'Producator activ'} value={active ? "True" : "False"} type={'text'} />
                    <PlaiuriTextInput label={'Status detalii'} value={reviewStatus} type={'text'} readonly />
                    <PlaiuriTextInput label={'Nume oficial firma'} value={producerName} type={'text'} onChange={setProducerName} />
                    <PlaiuriTextInput label={'Nume brandng'} value={marketingName} type={'text'} onChange={setMarketingName} />
                    <PlaiuriDropdownSelect label={'Tip firma'} options={Object.values(ProducerType)} value={producerType} onValueChange={(it) => setProducerType(it as ProducerType)} />
                    <PlaiuriTextInput label={'Regiune'} value={producerRegion} type={'text'} onChange={setProducerRegion} />
                    <PlaiuriTextarea rows={6} value={producerDescription} placeholder={"Descriere producator"} onChange={(it) => setProducerDescription(it)} />


                    <div className='flex'>
                        <button className="flex space-x-2 items-center px-4 py-2 bg-blue rounded-lg my-3 mx-2"
                            style={{ backgroundColor: stateChanged ? COLORS.primary : 'gray' }}
                            onClick={saveProducerDetails}>

                            {reviewStatus !== 'PENDING_REVIEW' &&  <span className="text-white text-lg">Salveaza</span>}
                            {reviewStatus === 'PENDING_REVIEW' &&  <span className="text-white text-lg">Updateaza draft</span>}
                        </button>

                        {
                            reviewStatus === 'PENDING_REVIEW' && <button className="flex space-x-2 items-center px-4 py-2 bg-blue rounded-lg my-3 mx-2"
                                style={{ backgroundColor: COLORS.primary }}
                                onClick={discardProducerDetailsDraft}>

                                <span className="text-white text-lg">Reseteaza versiune activa</span>
                            </button>
                        }
                    </div>
                </div>

                <div className='flex flex-1 my-3 mx-2'>
                    <div className='flex flex-1 flex-col items-center justify-start'>
                        <div className='mb-4'>
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                <p>Imagine producator (600 x 400)</p>
                            </label>
                            {
                                shouldShowNilImagePicker()
                                    ? <NilPictureImgPicker width={600} height={400} fileUploadCallback={fileUploadCallback} />
                                    : <PictureImgPicker width={600} height={400} imageUrl={imageUrl} />
                            }
                            <input type='file' accept="image/*" ref={productImageRef} onChange={onSelectImage}></input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProducerDetailsPage