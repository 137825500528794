import { useSelector } from 'react-redux';
import { UserDetails } from '../model/UserDetails';
import LoginPage from '../pages/LoginPage';
import { ApplicationState } from '../store/reducers';

type Props = {
    children: JSX.Element,
};

const AuthGuard = (props: Props) => {
    const userDetails = useSelector<ApplicationState, UserDetails | undefined>(state => state.user.user);

    console.log(userDetails)

    if (!userDetails) {
        return <LoginPage />
    }

    return (
        props.children
    )
}

export default AuthGuard