import { FiPlusCircle } from 'react-icons/fi';

interface Props {
    fileUploadCallback: () => void;
    width: number;
    height: number;
}

function NilPictureImgPicker(props: Props) {
    return (
        <div className={`bg-cover bg-center relative bg-gray-200 mb-2`} style={{width: props.width, height: props.height}}>
            <div className='flex flex-col absolute top-[50%] -translate-y-8 left-0 right-0 items-center justify-center'>
                <FiPlusCircle size={80} className="cursor-pointer text-gray-400" onClick={props.fileUploadCallback} />
            </div>
        </div>
    )
}

export default NilPictureImgPicker