import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import NilPictureImgPicker from "./NilPictureImgPicker";
import { producerStockSaveProduct } from "../store/actions/stock";
import { Product, QuantityUnitDetailsCompanionObject } from "../model/Product";
import { useAppDispatch } from "../store/store";
import { ApplicationState } from "../store/reducers";
import PictureImgPicker from "./PictureImgPicker";
import { PlaiuriTextInput } from "./PlaiuriTextInput";
import { PlaiuriDropdownSelect } from "./PlaiuriDropdownSelect";
import PlaiuriTextarea from "./PlaiuriTextarea";
import { COLORS } from "../constants/Colors";

export interface ProductProps {
    stockId: number;
    product: Product;
    onClick?: (p: Product) => void;
    editingProduct?: Product;
}

export const ProductDetailsEdit = (props: ProductProps) => {
    const productImageRef = useRef(null);

    const dispatch = useAppDispatch();

    const product: Product = useSelector<ApplicationState, Product>(state => state.stock.stock.products.find(it => it.id === props.product.id));

    const [loaded, setLoaded] = useState(false);
    const [title, setTitle] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageFile, setImageFile] = useState<File>(null);
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [quantityUnit, setQuantityUnit] = useState(null);

    const [stateChanged, setStateChanged] = useState(false);

    useEffect(() => {
        if (!product) {
            return;
        }

        setTitle(product.title);
        setImageUrl(product.imgUrl);
        setImageFile(null);
        setPrice(product.priceValue);
        setDescription(product.description);
        setCategory(product.category);
        setQuantity(product.quantity);
        setQuantityUnit(product.quantityUnit);
        setLoaded(true);

    }, [product]);

    useEffect(() => {
        setStateChanged(false
            || title !== product.title
            || imageUrl !== product.imgUrl
            || imageFile !== null
            || price !== product.priceValue
            || description !== product.description
            || category !== product.category
            || quantity !== product.quantity
            || quantityUnit !== product.quantityUnit);

    }, [title, imageUrl, imageFile, price, description, category, quantity, quantityUnit]); // react-hooks/exhaustive-deps

    const fileUploadCallback = () => {
        productImageRef.current.click();
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!imageFile) {
            return;
        }

        const objectUrl = URL.createObjectURL(imageFile)
        setImageUrl(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const onSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImageFile(undefined);
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setImageFile(e.target.files[0])
    }

    const shouldShowNilImagePicker = () => {
        if (!imageUrl || imageUrl.length === 0) {
            return true;
        }

        return false;
    }

    const saveProductDetails = async () => {
        const productUpdates = {
            title: title,
            priceValue: price,
            description: description,
            category: category,
            quantity: quantity,
            quantityUnit: quantityUnit,
        };

        dispatch(producerStockSaveProduct(props.stockId, product.id, productUpdates as Product, imageFile));
    }

    if (!product || !loaded) {
        return (
            <div className="bg-white rounded-md mx-2 p-6 px-8 h-[1000px] sticky top-20 flex-1">
            </div>
        );
    }

    return (
        <div className='bg-white rounded-md mx-2 p-6 px-8 h-[1000px] sticky top-20 flex-1'>
            <h2 className='text-3xl'>Informatii Produs</h2>
            {
                product && (
                    <div className='flex flex-col my-2'>

                        {/* Image picker */}
                        <div className='mb-4'>
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                <p>Product image (300 x 300)</p>
                            </label>
                            {
                                shouldShowNilImagePicker() 
                                    ? <NilPictureImgPicker width={300} height={300} fileUploadCallback={fileUploadCallback} /> 
                                    : <PictureImgPicker width={300} height={300} imageUrl={imageUrl}  />
                            }
                            <input type='file' accept="image/*" ref={productImageRef} onChange={onSelectImage}></input>
                        </div>


                        {/* Title */}
                        <PlaiuriTextInput label={'Titlu'} type={'text'} value={title} onChange={it => setTitle(it)} />

                        {/* Price / Quantity / Units  */}
                        <div className='flex flex-row my-3'>
                            {/* Price */}
                            <PlaiuriTextInput label={`Pret (${product.priceCurrency})`} type={'number'} value={price} onChange={it => setPrice(+it)} />

                            {/* Stock quantity */}
                            <PlaiuriTextInput label={'Cantitate stoc'} type={'number'} value={quantity} onChange={it => setQuantity(+it)} />

                            {/* Quantity units */}
                            <PlaiuriDropdownSelect
                                label={'Unitate'}
                                options={QuantityUnitDetailsCompanionObject.getAllValues().map(it => it.plural)}
                                value={QuantityUnitDetailsCompanionObject.makeFromEnum(quantityUnit)?.plural}
                                onValueChange={it => setQuantityUnit(QuantityUnitDetailsCompanionObject.makeFromString(it).quantityUnit)} />
                        </div>

                        {/* Description */}
                        <PlaiuriTextarea rows={6} value={description} placeholder={"Descriere produs"} onChange={(it) => setDescription(it)} />

                        {/* Update button */}
                        <button className="flex space-x-2 items-center px-4 py-2 bg-blue rounded-lg my-3 mx-2 w-1/4"
                            style={{ backgroundColor: stateChanged || product.id < 0 ? COLORS.primary : 'gray' }}
                            onClick={saveProductDetails}>

                            <span className="mx-auto text-white text-lg">Salveaza</span>
                        </button>

                    </div>
                )
            }
        </div>
    )
}


