import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProducerStockCategory from '../components/ProducerStockCategory';
import ProducerStockDropdown from '../components/ProducerStockDropdown';
import { ProductDetailsEdit } from '../components/ProductDetails';
import { Producer } from '../model/Producer';
import { ProducerStock } from '../model/ProducerStock';
import { Product } from '../model/Product';
import { ProductCategory } from '../model/ProductCategory';
import { producerGetStock, producerStockGetAllCategories } from '../store/actions/stock';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProducerStockPage() {

    const dispatch = useAppDispatch();

    const producer: Producer = useSelector<ApplicationState, Producer | undefined>(state => state.producerAdmin.producers[0]);
    const stock: ProducerStock = useSelector<ApplicationState, ProducerStock | undefined>(state => state.stock.stock);
    const categories: ProductCategory[] = useSelector<ApplicationState, ProductCategory[]>(state => state.stock.categories);

    const [editingProduct, setEditingProduct] = useState<Product>(null);
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

    const loadData = useCallback(async () => {
        if (!producer) {
            return;
        }

        await dispatch(producerGetStock(producer.id));
        await dispatch(producerStockGetAllCategories());

    }, [dispatch, producer]);

    // Data load
    useEffect(() => {
        loadData().then(() => { console.log('Data loaded.') });
    }, [dispatch, loadData]);

    // Populate categories on data load update
    useEffect(() => {
        if (stock === null || categories === null) {
            return;
        }

        const updatedProducerCategories = stock.products
            .map(it => it.category)
            .filter(itc => categories.map(it => it.id).includes(itc.id))
            .filter((v, i, a) => a.map(it => it.id).indexOf(v.id) === i);

        setProductCategories(updatedProducerCategories);
    }, [stock, categories]);

    const onCategoryAdded = (category: ProductCategory) => {
        const updatedCategories = [category, ...productCategories]
            .filter((v, i, a) => a.map(it => it.id).indexOf(v.id) === i)
            .sort((it1, it2) => it1.orderId - it2.orderId)

        setProductCategories(updatedCategories)
    };

    if (stock === null || categories === null) {
        return <></>
    }

    return (
        <div className='mt-4 flex-1'>
            <div className={'grid grid-cols-2'}>

                {/* LEFT panel */}
                <div className='bg-white rounded-md p-4 px-4 mx-2 flex-1 min-h-[1000px]'>
                    {/* Header. */}
                    <div className='flex items-center'>
                        <h2 className='text-3xl flex-1'>Informatii stoc</h2>
                        <ProducerStockDropdown onCategoryAdded={onCategoryAdded} />
                    </div>

                    {/* Categories */}
                    <div>
                        {
                            productCategories.sort((it1, it2) => it1.id - it2.id)
                                .map((it) => (<ProducerStockCategory key={it.id}
                                                                     category={it}
                                                                     onProductClick={it => { setEditingProduct(it === editingProduct ? null : it) }}
                                                                     editingProduct={editingProduct} />
                            ))
                        }
                    </div>
                </div>

                {/* RIGHT Panel */}
                {editingProduct && <ProductDetailsEdit stockId={stock.id} product={editingProduct} key={editingProduct?.id} />}
            </div>
        </div >
    )
}

export default ProducerStockPage

