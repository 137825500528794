import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Endpoints } from "../../constants/Endpoints";
import { Producer } from "../../model/Producer";
import { ApplicationState } from "../reducers";

export interface ProducerAdminGetProducersAction extends Action {
    type: "@@adminProducer/GET_PRODUCERS";
    payload: {
        producers: Producer[];
    }
}

export const producerAdminGetProducers = (): ThunkAction<any, {}, {}, ProducerAdminGetProducersAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, ProducerAdminGetProducersAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/producer`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to get producer admin producers task.');
            return;
        }

        const producersDetailsResponse = await response.json() as Producer[];
        
        dispatch({
            type: "@@adminProducer/GET_PRODUCERS",
            payload: {
                producers: producersDetailsResponse
            }
        });
    }
}

export interface ProducerAdminSaveProducerAction extends Action {
    type: "@@adminProducer/SAVE_PRODUCER";
    payload: {
        producer: Producer;
    }
}

export const producerAdminSaveProducer = (id: number, producerUpdates: Producer, file: any): ThunkAction<any, {}, {}, ProducerAdminSaveProducerAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, ProducerAdminSaveProducerAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        let response = await fetch(`${Endpoints.baseUrl}/producer/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(producerUpdates)
        });

        if (!response.ok) {
            alert('Failed to save producer details.');
            return;
        }

        let updatedProducer = await response.json() as Producer;

        if (file) {
            /*
             * Also update image on the (possibly) newly created product id
             */
            const formData = new FormData();
            formData.append("file", file);

            response = await fetch(`${Endpoints.baseUrl}/producer/${id}/image`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                alert('Failed to upload producer image.');
                return;
            }

            updatedProducer = await response.json() as Producer;
        }
        
        dispatch({
            type: "@@adminProducer/SAVE_PRODUCER",
            payload: {
                producer: updatedProducer
            }
        });
    }
}

export interface ProducerAdminGetProducerHistory extends Action {
    type: "@@adminProducer/GET_PRODUCER_HISTORY";
    payload: {
        history: Producer[];
    }
}

export const producerAdminGetProducerHistory = (id: number): ThunkAction<any, {}, {}, ProducerAdminGetProducerHistory> => {
    return async (dispatch: ThunkDispatch<{}, {}, ProducerAdminGetProducerHistory>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/producer-history/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to get producer admin producers task.');
            return;
        }

        const historyResponse = await response.json() as Producer[];
        
        dispatch({
            type: "@@adminProducer/GET_PRODUCER_HISTORY",
            payload: {
                history: historyResponse
            }
        });
    }
}

export const producerAdminDiscardProducerDetailsDraftAction = (id: number): ThunkAction<any, {}, {}, ProducerAdminSaveProducerAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, ProducerAdminSaveProducerAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        let response = await fetch(`${Endpoints.baseUrl}/producer/${id}/discard`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to discard producer details draft.');
            return;
        }

        const producer = await response.json() as Producer;
        
        dispatch({
            type: "@@adminProducer/SAVE_PRODUCER",
            payload: {
                producer: producer
            }
        });
    }
}

export type ProducerAdminAction = ProducerAdminGetProducersAction | ProducerAdminSaveProducerAction | ProducerAdminGetProducerHistory;