import { useState } from "react";

interface PlaiuriTextInputProps {
    label: string;
    value: any;
    type: string;
    placeholder?: string;
    readonly?: boolean;
    onChange?: (text: string) => void;
    onSubmit?: (text: string) => void;

}

export const PlaiuriTextInput = (props: PlaiuriTextInputProps) => {

    const [value, setValue] = useState(props.value);

    const onChange = (newValue: string) => {
        setValue(newValue);
        props.onChange(newValue);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            props.onSubmit(value);
            setValue("");
        }
    };

    return (
        <div className="w-80 mb-0 my-3 mx-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                {props.label}
            </label>
            <input className={`appearance-none block w-full bg-gray-100 ${props.readonly ? 'text-gray-400' : 'text-gray-700' } border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name`}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onKeyDown={handleKeyDown}
                onChange={it => onChange(it.target.value)} />

        </div>
    );
}