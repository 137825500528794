import { Table } from 'flowbite-react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Producer } from '../model/Producer';
import { producerAdminGetProducerHistory } from '../store/actions/producer';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProducerDetailsHistoryPage() {
    const dispatch = useAppDispatch();

    const producer: Producer = useSelector<ApplicationState, Producer | undefined>(state => state.producerAdmin.producers[0]);
    const producerHistory: Producer[] = useSelector<ApplicationState, Producer[]>(state => state.producerAdmin.history);

    useEffect(() => {
        if (!producer) {
            return;
        }

        dispatch(producerAdminGetProducerHistory(producer.id));
    }, [dispatch]);

    return (
        <div className='flex flex-col w-full mt-4 bg-white rounded-md p-4 px-4 mx-2'>
            <p className='m-4 text-3xl'>Istoric detalii producator</p>
            <div className="m-4 mt-8">
                <Table striped={true}>

                    <Table.Head>
                        <Table.HeadCell>
                            Versiune
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Autor
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Data
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Nume oficial firma
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Nume branding
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Tip firma
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Regiune
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Descriere producator
                        </Table.HeadCell>
                    </Table.Head>

                    <Table.Body className="divide-y">
                        {
                            producerHistory.sort((it1, it2) => it2.version - it1.version)
                                .map(it => (
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell>
                                            {it.version}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.versionAuthor}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {new Date(it.versionDate).toLocaleString("en-US")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.marketingName}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.producerType !== 'PRODUCATOR_AUTORIZAT' ? it.producerType : "PA"}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.region}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.description}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default ProducerDetailsHistoryPage